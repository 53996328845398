<template>
  <v-container class="mt-3 mb-3">
    <v-sheet>
      <div class="page-content">
        <v-card-text>
          <h1 class="text-h3 mb-4 mt-4 font-weight-bold">
            Privacy Policy
          </h1>

          <p>
            This privacy policy applies between you, the User of this Website and NICE BLUE LTD Trading as Brick Lane
            Brasseri , the owner and provider of this Website. NICE BLUE LTD Trading as Brick Lane Brasseri takes the
            privacy of your information very seriously. This privacy policy applies to our use of any and all Data
            collected by us or provided by you in relation to your use of the Website.
          </p>
          <p>
            This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be
            found at: “https://bricklanebrasserie.com to T&C”
          </p>

          <p class="font-weight-bold">
            Please read this privacy policy carefully.
          </p>
          <h2 class="text-h3 mb-5 mt-5">
            Definitions And Interpretation
          </h2>
          <p class="text-h6 font-weight-bold">
            1. In this privacy policy, the following definitions are used:
          </p>

          <table class="mt-3 mb-2">
            <tbody>
              <tr>
                <td class="mr-5">
                  <strong>Data</strong>
                </td>
                <td>
                  <p>
                    Collectively all information that you submit to NICE BLUE LTD Trading as Brick Lane Brasseri via
                    the Website. This definition incorporates, where applicable, the definitions provided in the Data
                    Protection Laws;
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>Cookies</strong>
                </td>
                <td>
                  <p>
                    a small text file placed on your computer by this Website when you visit certain parts of the
                    Website and/or when you use certain features of the Website. Details of the cookies used by this Website
                    are set out in the clause below (Cookies);
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>Data Protection Laws</strong>
                </td>
                <td>
                  <p>
                    any applicable law relating to the processing of personal Data, including but not limited to the
                    Directive 96/46/EC (Data Protection Directive) or the GDPR, and any national implementing laws,
                    regulations and secondary legislation, for as long as the GDPR is effective in the UK;
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>NICE BLUE LTD Trading as Brick Lane Brasseri , or us</strong>
                </td>
                <td>
                  <p>
                    NICE BLUE LTD Trading as Brick Lane Brasseri , a company incorporated in England and Wales with
                    registered number 11674747 whose registered office is at Brick Lane Brasserie, 67 Brick Lane, London, E1
                    6QL
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>GDPR</strong>
                </td>
                <td><p>the General Data Protection Regulation (EU) 2016/679;</p></td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>UK and EU Cookie Law</strong>
                </td>
                <td>
                  <p>
                    the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy
                    and Electronic Communications (EC Directive) (Amendment) Regulations 2011;
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>User or you</strong>
                </td>
                <td>
                  <p>
                    any third party that accesses the Website and is not either (i) employed by NICE BLUE LTD Trading
                    as Brick Lane Brasseri and acting in the course of their employment or (ii) engaged as a consultant or
                    otherwise providing services to NICE BLUE LTD Trading as Brick Lane Brasseri and accessing the Website
                    in connection with the provision of such services; and
                  </p>
                </td>
              </tr>

              <tr>
                <td class="mr-5">
                  <strong>Website</strong>
                </td>
                <td>
                  <p>
                    the website that you are currently using, https://bricklanebrasserie.com/, and any sub-domains of
                    this site unless expressly excluded by their own terms and conditions.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-h6 font-weight-bold">
            2. In this privacy policy, unless the context requires a different
            interpretation:
          </p>
          <p>the singular includes the plural and vice versa;</p>
          <p>
            references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or
            appendices of this privacy policy;
          </p>
          <p>a reference to a person includes firms, companies, government entities, trusts and partnerships;</p>
          <p>"including" is understood to mean "including without limitation";</p>
          <p>reference to any statutory provision includes any modification or amendment of it; </p>
          <p>the headings and sub-headings do not form part of this privacy policy.</p>

          <h2 class="text-h3 mb-5 mt-5">
            Scope Of This Privacy Policy
          </h2>
          <p>
            This privacy policy applies only to the actions of NICE BLUE LTD Trading as Brick Lane Brasseri and Users
            with respect to this Website. It does not extend to any websites that can be accessed from this Website
            including, but not limited to, any links we may provide to social media websites.
          </p>
          <p>
            For purposes of the applicable Data Protection Laws, NICE BLUE LTD Trading as Brick Lane Brasseri is the
            "data controller". This means that NICE BLUE LTD Trading as Brick Lane Brasseri determines the purposes for
            which, and the manner in which, your Data is processed.
          </p>

          <h2 class="text-h3 mb-5 mt-5">
            Data Collected
          </h2>

          <p class="font-weight-bold">
            We may collect the following Data, which includes personal Data, from you:
          </p>
          <ul class="page-content__list-item">
            <li>name</li>
            <li>date of birth;</li>
            <li>gender;</li>
            <li>job title;</li>
            <li>profession;</li>
            <li>contact Information such as email addresses and telephone numbers;</li>
            <li>contact Information such as email addresses and telephone numbers;</li>
            <li>demographic information such as postcode, preferences and interests</li>
            <li>financial information such as credit / debit card numbers;</li>
            <li>IP address (automatically collected);</li>
            <li>web browser type and version (automatically collected);</li>
            <li>operating system (automatically collected);</li>
            <li>
              a list of URLs starting with a referring site, your activity on this Website, and the site you exit to
              (automatically collected);
            </li>
            <li>in each case, in accordance with this privacy policy.</li>
          </ul>
        </v-card-text>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style lang="scss"></style>
